.mentor-page {
  h1 {
    color: #4d4d4d;
  }
}

.community-item {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 152px;
  width: 100%;
  margin-bottom: 16px;

  .ant-card-body {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    margin: 0;
    padding: 0;
  }
  &__img {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
  }

  &__middle {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 24px !important;
    margin-bottom: 0 !important;
    .community-title {
      width: 100%;
    }
    .community-desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__end {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    &__row {
      padding-top: 0;
    }

    &__middle {
      margin-top: 8px;
    }
  }
}

.connection-request {
  &__btn-group {
    justify-content: flex-end;
    display: flex;
  }

  @media screen and (max-width: 768px) {
    &__btn-group {
      margin-top: 24px !important;
    }
  }

  &__btn-close {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    color: #c00000;
    border: 1px solid #c00000;
    i {
      font-size: 24px;
    }
  }

  &__btn-accept {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    color: white;
    background: #64ac07;
    border: 1px solid #64ac07;
    i {
      font-size: 24px;
    }
    &:hover,
    &:focus {
      background: #64ac07;
      border: 1px solid #64ac07;
    }
  }

  &__notes {
    background: #f2f2f2;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
  }
}

.mentor-carousel {
  .slick-dots {
    width: calc(100% - 26%);
    margin-bottom: -40px;
    button {
      background: #969696 !important;
    }
    .slick-active {
      button {
        background: #c00000 !important;
      }
    }
  }
}

.group-invitation {
  background: #f2f2f2;
  border-radius: 16px;
  margin-top: 24px;
  padding: 16px 8px;

  .img {
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .group-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 12px;
  }
  .group-name {
    color: '#333';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    .img {
      height: 140px;
      margin-bottom: 8px;
    }
  }
}

.detail__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--name-type {
    display: flex;
    align-items: center;
    .ant-typography {
      margin-bottom: 0;
      margin-right: 16px;
    }
    .ant-tag {
      border-radius: 16px;
      border: none;
      padding: 2px 12px;
    }
  }
  .member,
  .mentee {
    background-color: #e2edf8;
    color: #0a66c2;
  }
  .mentor {
    background: #f7e0e0;
    color: #c00000;
  }
  .ant-btn {
    border-color: black;
    color: black;
    &:hover {
      color: gray;
    }
  }
  .ant-btn-dangerous,
  .ant-btn-primary {
    color: white;
    border-color: unset;
    &:hover {
      color: #efefef;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
    .ant-tag {
      margin: 16px 0;
    }
    display: flex;
    flex-direction: column;
    &--name-type {
      flex-direction: column;
    }
  }
}

.info-avatar {
  display: flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.card__info {
  margin-top: 16px;
  background: #f2f2f2;
  .ant-tag {
    padding: 4px 12px;
    border-radius: 8px;
    color: black;
  }
  .ant-col {
    margin-bottom: 0 !important;
  }
  .ant-row {
    margin-top: 32px;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #0006c0;
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    .ant-col {
      margin-top: 16px;
    }
  }
}

.ant-modal-footer {
  text-align: center;
  padding-bottom: 24px !important;
  .ant-btn {
    border-color: black;
    &:first-child {
      &:hover {
        color: gray;
      }
    }
    &:last-child {
      background: #4d4d4d;
      color: white;
    }
  }
}

.ant-modal-title {
  color: black;
}

.ant-modal-body {
  text-align: center;
  padding-bottom: 24px !important;
  .ant-radio-group.ant-radio-group-outline {
    text-align: left;
  }
}
