@import '../../../../shared/styles/variable';

.info-upload {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .ant-form-item-control-input-content {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.info-upload-avatar {
  margin-right: 0px;
  width: 100%;
  margin-bottom: 10px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 16px;
  }

  @media screen and (min-width: @screen-lg) {
    width: 64px;
    height: 64px;
  }
}

.info-upload-content {
  flex: 1;
  font-size: @font-size-sm;
  color: @text-color-secondary;

  & p {
    margin-bottom: 0;
  }
}

.info-upload-btn-group {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .dropzone {
    margin-right: 10px;
    margin-left: 10px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  & .ant-btn {
    height: 30px;
    padding: 3.5px 12px;
  }
}

.mentor-card {
  background: #e2edf8;
  padding: 8px 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: left;
  h3 {
    text-transform: uppercase;
    color: #0a66c2;
    font-size: 16px;
    font-weight: 500;
  }

  button,
  button:hover {
    background: #0a66c2;
    border: #0a66c2;
  }
}
