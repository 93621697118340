.pointer {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.item-hover {
  transition: all 0.2s ease;
  transform: scale(1);

  &:hover {
    background-color: fade(@primary-color, 10);
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 fade(@black, 20);
  }
}

.ml--16 {
  margin-left: -16px;
}

.ml--12 {
  margin-left: -12px;
}

.card-hover {
  transition: all 0.3s ease;
  transform: scale(1);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45);
    transform: scale(1.05);
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;

  [dir='rtl'] & {
    text-align: left;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.cr-btn {
  box-sizing: border-box;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}

.cr-btn-circle {
  background-color: transparent;
  border-color: transparent;
  color: @grey-500;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: fade(@tooltip-bg, 4);
    border-color: fade(@tooltip-bg, 4);
    color: @text-color;
  }

  & .anticon {
    font-size: 20px;
  }
}

.btn-secondary {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @white;

  &:hover,
  &:focus {
    background-color: darken(@secondary-color, 25);
    border-color: darken(@secondary-color, 25);
    color: @white;
  }
}

.btn-light-blue {
  background-color: fade(@primary-color, 10);
  color: @primary-color;
  border-color: fade(@primary-color, 10);

  &:hover,
  &:focus {
    background-color: fade(@primary-color, 20);
    color: @primary-color;
    border-color: fade(@primary-color, 20);
  }
}

.btn-secondary-outline {
  background-color: transparent;
  border-color: @secondary-color;
  color: @secondary-color;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: darken(@secondary-color, 15);
    color: darken(@secondary-color, 15);
  }
}

.btn-primary-outline {
  background-color: transparent;
  border-color: @primary-color;
  color: @primary-color;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: darken(@primary-color, 15);
    color: darken(@primary-color, 15);
  }
}

.btn-white-outline {
  background-color: transparent;
  border-color: @white;
  color: @white;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: @white;
    color: @white;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.dot {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: @border-radius-circle;
}

.cr-dropdown-link {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: @border-radius-circle;
  color: fade(@tooltip-bg, 84) !important;
  font-size: 20px;

  &:hover {
    background-color: fade(@tooltip-bg, 10);
  }
}

.close-btn {
  background-color: transparent;
  box-shadow: none;
  border: 0 none;
  padding: 0;
  height: auto;

  &:hover,
  &:focus {
    border: 0 none;
  }

  & .anticon {
    font-size: 20px;
  }
}

.page-title {
  color: @text-color;
  font-weight: @font-weight-bold;
  margin-bottom: 16px;
  font-size: 32px;
  display: inline-block;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 12px;
}

.ant-modal {
  max-width: 90%;
}

.text-secondary {
  color: @text-color-secondary;
}

.text-green {
  color: @green-6;
}

.slick-slider-global .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;

  & button {
    width: 12px;
    height: 12px;
    padding: 0;
  }

  & button::before {
    width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    color: #be8658;
  }
}

.slick-slider-global .slick-dots li.slick-active button::before {
  color: #825a44;
  opacity: 0.75;
}

.z-index-20 {
  z-index: 20;
}

.ant-picker {
  padding-top: @input-padding-vertical-base;
  padding-bottom: @input-padding-vertical-base;
}

.ant-pagination {
  & li {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  &.mini {
    & .ant-pagination-item,
    & .ant-pagination-prev,
    & .ant-pagination-next {
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }
}

.ant-drawer,
.ant-modal-wrap,
.ant-modal-mask,
.ant-picker-dropdown,
.ant-select-dropdown,
.ant-message {
  z-index: 1051;
}

.ant-drawer-close {
  color: @text-color-secondary;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 11px;
  right: 6px;
  padding: 5px;

  &:hover,
  &:focus {
    background-color: fade(@black, 5);
    color: @text-color;
  }
}

.ant-input:focus,
.ant-input:not(:focus),
.ant-input:not(:hover),
.ant-input:active,
.ant-input:hover,
.ant-input-affix-wrapper {
  border: solid 1px #f7f7f7;
  box-shadow: none;
  background: #f7f7f7;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  box-shadow: none;
  border-width: 0px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffeae6 !important;
}

.ant-typography strong {
  font-weight: 500;
}

.text-bold {
  font-weight: 500;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-radius: 4px;
  box-shadow: none;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  text-align: center;
  margin-bottom: 24px;

  .ant-btn {
    border-color: #c00000;
    color: #c00000;
    padding-left: 30px;
    padding-right: 30px;
  }

  .ant-btn-primary {
    color: white;
    border-color: @primary-color;
  }
}

.ant-modal-confirm-title {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0168em;
  color: #0a66c2 !important;
  text-align: center;
  margin-bottom: 16px;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0;
}

.ant-modal-confirm-content {
  text-align: center;
  margin-top: 48px;
}

.ant-modal-confirm-body {
  .anticon-exclamation-circle {
    display: none;
  }
}

// Ant Tooltip
.ant-tooltip-inner {
  & a {
    color: @text-color-white;
    display: flex;
    align-items: center;

    & .ant-menu-item-icon {
      margin-right: 6px;

      & .anticon,
      & svg {
        display: block;
      }
    }
  }
}

.d-100 {
  width: 100%;
}

.btn-favor,
.btn-active {
  i,
  span {
    color: #c00000 !important;
  }
}
.btn-inactive {
  i,
  span {
    color: #808080 !important;
  }
}

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-16 {
  margin: 16px;
}

.m-24 {
  margin: 24px;
}

.m-32 {
  margin: 32px;
}

.p-4 {
  padding: 4px;
}

.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.p-32 {
  padding: 32px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-32 {
  padding-left: 32px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-32 {
  padding-right: 32px;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.btn-primary2 {
  background: #f7e0e0;
  border-color: #f7e0e0;
  color: #c00000;
  &:hover {
    background: #f6cfcf;
    border-color: #f7e0e0;
    color: #c00000;
  }
  &:focus,
  &:active {
    background: #f7e0e0;
    border-color: #f7e0e0;
    color: #c00000;
  }
}

.btn-secondary2 {
  background: #e2edf8;
  border-color: #e2edf8;
  color: #0a66c2;
  &:hover {
    background: #c4def8;
    border-color: #e2edf8;
    color: #0a66c2;
  }
  &:focus,
  &:active {
    background: #e2edf8;
    border-color: #e2edf8;
    color: #0a66c2;
  }
}

.btn-black-link {
  color: #000000;
  padding: 0;
  display: flex;
  align-items: center;

  span {
    color: #000000;
  }
  font-weight: 400 !important;

  &:active,
  &:focus {
    color: #000000;
    span {
      color: #000000;
    }
  }
}

.btn-primary-link {
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.btn-black {
  background: rgba(0, 0, 0, 0.7);
  color: white !important;
  border-color: rgba(0, 0, 0, 0.7) !important;
  &:hover {
    background: rgba(8, 7, 7, 0.9);
    color: white !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.d-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.d-justify-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-gray {
  background: #f9f9f9 !important;
  border: none;
}

.t-gray {
  color: #808080 !important;
}

.t-center {
  text-align: center;
}

.btn-normal {
  span {
    text-transform: none;
  }
}

.btn-pointer {
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
}

.btn-link {
  &:hover {
    color: #c00000;
  }
}

.text-primary {
  color: #c00000;
}
.text-primary-i {
  color: #c00000 !important;
}

.text-secondary {
  color: #0a66c2 !important;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 36px;
}

.font-48 {
  font-size: 48px;
}

.ant-btn-dangerous {
  color: #c00000;
  border-color: #c00000;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.d-flex {
  display: flex;
}

.d-flex-1 {
  flex: 1;
}

.ant-form-item-label > label {
  font-weight: 500;
  font-size: 14px;
}
.select-icon {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 40px;
  }
}
.text-underline {
  text-decoration: underline;
}

.text-gray {
  color: #808080;
}

.enter-content {
  white-space: pre-line;
}

.text-left {
  text-align: left;
}

.footer {
  position: relative;
  width: 100%;
  height: 58px;
  left: 0px;
  bottom: 0px;
  margin-top: 86px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  .content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-top: 5px;

    letter-spacing: 0.0168em;
    font-feature-settings:
      'tnum' on,
      'lnum' on;
    color: #333333;
    position: absolute;
    left: 80px;
    @media screen and (max-width: @screen-sm) {
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

.ant-tag-blue,
.ant-tag-orange,
.ant-tag-red {
  border: none;
  padding: 4px 8px;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.color-unset {
  color: unset !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: #b6d1ed;
  color: white;
}

.input-chat {
  background: #f2f2f2;
  .ant-input-group-addon,
  input {
    background: #f2f2f2 !important;
  }
}

.text-gray {
  color: #808080;
}

.user-profile-footer {
  position: fixed;
  bottom: 54px !important;
  left: 0;
  right: 0;
  padding: 17px;
  background: #fff;
  border: 1px solid #e6e6e6;

  & .user-profile-footer_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
}
