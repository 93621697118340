.article-detail {
  width: 100%;
  background: #f9f9f9;
  min-height: 100vh;

  &-bg {
    .ant-image {
      width: 100%;
      height: 480px !important;
      overflow: hidden;
      background: #343232;
      img {
        height: 100%;
      }
    }
  }
  &__cover {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }

  &__content {
    background: white;
    padding: 5vw 10vw;
    min-height: 80vh;

    p {
      color: #808080;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.terms-and-conditions {
}
