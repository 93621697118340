@import '~antd/lib/style/themes/default.less';

// Font Family
@font-family: 'Roboto';

// Font Weight
@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-bold: 600;

// Background color for `<body>`
@body-background: #ffffff;
// Base background color for most components
@component-background: #fff;

//global style variables
@primary-color: #333333;
@secondary-color: #0a8fdc;

@error-color: #c00000;

@text-color: #333333; // text primary color
@text-color-secondary: rgb(107, 114, 128);
@text-color-disabled: rgb(149, 156, 169);
@text-color-hint: #aeafb8;
@text-color-white: @white;

@sidebar-bg-color: @white;
@sidebar-text-color: @text-color;
@sidebar-dark-bg-color: #001529;
@sidebar-curved-menu-color: #f4f7fe;

@heading-color: @text-color;
@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;
@heading-1-size: ceil(@font-size-base * 1.572);
@heading-2-size: ceil(@font-size-base * 1.429);
@heading-3-size: ceil(@font-size-base * 1.286);
@heading-4-size: ceil(@font-size-base * 1.143);
@heading-5-size: ceil(@font-size-base * 1);
@heading-6-size: ceil(@font-size-base * 1);
// https://github.com/ant-design/ant-design/issues/20210
@line-height-base: 1.35;
@border-radius-base: 4px;
@border-radius-sm: @border-radius-base;
@border-radius-30: 30px;
@border-radius-circle: 50%;

@nav-bar-bg: @sidebar-bg-color;
@nav-bar-text-color: @sidebar-text-color;

// Border color
@border-color-base: rgba(0, 0, 0, 0.12); // base border outline a component

// Grey Color Variables
@grey-50: #fafafa;
@grey-100: #f5f5f5;
@grey-200: #eeeeee;
@grey-300: #e0e0e0;
@grey-400: #bdbdbd;
@grey-500: #9e9e9e;
@grey-600: #757575;
@grey-700: #616161;
@grey-800: #424242;
@grey-900: #212121;

// Card
// ---
@card-head-color: @text-color;
@card-head-font-size: @font-size-lg;
@card-head-padding: 16px;
@card-head-padding-sm: (@card-head-padding / 2);
@card-head-height: 48px;
@card-head-height-sm: 36px;
@card-inner-head-padding: 12px;
@card-padding-base: 24px;
@card-background: @component-background;
@card-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
@card-radius: 16px;

//Col Space
@col-margin-base: 32px;
@col-margin-res: 16px;

// Input
// ---
@input-height-base: 48px;
@input-height-lg: 56px;
@input-height-sm: 32px;
@input-padding-horizontal: 11px;
@input-padding-horizontal-base: 14px;
@input-padding-horizontal-sm: 12px;
@input-padding-horizontal-lg: 14px;
@input-padding-vertical-base: max(
  (
      round(
          ((@input-height-base - @font-size-base * @line-height-base) / 2) * 10
        ) / 10
    ) - @border-width-base,
  3px
);
@input-padding-vertical-sm: max(
  (
      round(((@input-height-sm - @font-size-base * @line-height-base) / 2) * 10) /
        10
    ) - @border-width-base,
  0
);
@input-padding-vertical-lg: (
    ceil(((@input-height-lg - @font-size-lg * @line-height-base) / 2) * 10) / 10
  )
  @input-placeholder-color: hsv(0, 0, 75%);
@input-color: @text-color;
@input-bg: #f7f7f7;
@input-border-color: @input-bg;
@input-hover-border-color: @input-bg;

@dropdown-selected-bg: @input-bg;
@select-background: @input-bg;
@select-border-color: @input-bg;
@picker-bg: @input-bg;
@picker-border-color: @input-bg;
// Rate
// ---
@rate-star-color: @yellow-6;
@rate-star-bg: rgba(0, 0, 0, 0.26);

// Buttons
@btn-height-base: 38px;
@btn-height-lg: 48px;
@btn-height-sm: 32px;
@btn-primary-bg: @primary-color;
@btn-border-radius-base: 8px;
@btn-border-radius-sm: 8px;
@btn-line-height: 1;

//Framed Layout
@framed-layout-base: 20px;

@modal-header-border-width: 0;
@modal-footer-border-width: 0;
@modal-heading-color: @secondary-color;
