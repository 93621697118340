@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.ant-layout {
  background: @body-background !important;
}

.app-layout-hor-header-fixed {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column !important;

  & .customizer-option {
    position: fixed;
    top: 138px;
  }

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .app-main-footer {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }
}

.app-main-hor-header-fixed-sidebar {
  &.ant-layout-sider {
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 0 !important;
    width: 17.5rem !important;
    .transition(all 0.1s linear);
    background-color: @sidebar-bg-color;
    color: @sidebar-text-color;
    position: fixed;
    left: 0;
    top: 0;

    [dir='rtl'] & {
      left: auto;
      right: 0;
    }

    @media screen and (min-width: @screen-lg) {
      width: 17.5rem !important;
    }
  }

  & .ant-layout-sider-trigger {
    display: none;
  }

  &.ant-layout-sider-has-trigger {
    padding-bottom: 0;
  }

  // Sidebar Collapsed
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    .transition(all 200ms linear);

    @media screen and (min-width: @screen-lg) {
      width: 4rem !important;
    }

    & + .app-layout-main {
      width: 100% !important;
      .transition(all 200ms linear);

      @media screen and (min-width: @screen-lg) {
        width: calc(100% - 4rem) !important;
      }
    }

    & .cr-user-info {
      padding-left: 12px;
      padding-right: 12px;
    }

    & .cr-user-info-content {
      opacity: 0;
      visibility: hidden;
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }

    & .ant-menu-item-group-title {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
    }
  }
}

.app-header-hor-header-fixed {
  background-color: inherit;
  color: inherit;
  border-bottom: @border-style-base @border-width-base @border-color-base;
  height: auto;
  line-height: 1;
  padding: 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;

  & .trigger {
    font-size: 24px;
    margin-right: 20px;
    padding: 5.5px;
    color: inherit;

    @media screen and (min-width: @screen-lg) {
      display: none;
    }

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.header-search-hor-header-fixed {
  position: relative;
  min-height: 36px;
  margin-right: 10px;

  @media screen and (min-width: @screen-sm) {
    margin-right: 25px;
  }

  & .ant-input-wrapper {
    z-index: 1;
    background: rgb(247, 247, 247);
    border-radius: 8px;
    height: 48px;
  }

  & .ant-input {
    padding: 8px 14px;
    height: 48px;
    .transition(all 0.2s ease);
    min-width: 104px;
    margin-right: 0 !important;

    &:hover {
      outline: none;
      border-width: 0 !important;
      border-color: transparent !important;
    }

    &:focus {
      width: 135px;
      border: none !important;
      outline: none;
      border-width: 0 !important;
      border-color: transparent !important;
      box-shadow: none;
    }
    border: none !important;
    outline: none;

    @media screen and (min-width: @screen-lg) {
      width: 300px;

      &:focus {
        width: 350px;
      }
    }
  }

  & .ant-input-search-button {
    height: 48px;
    width: 48px;
    box-shadow: none;

    & .anticon svg {
      display: block;
    }
  }

  &.ant-input-search-rtl
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }
}

.app-header-hor-header-fixed-sectionDesktop {
  display: none;

  @media screen and (min-width: @screen-md) {
    display: flex;
    align-items: center;
  }
}

.app-header-hor-header-fixed-section-mobile {
  display: block;

  @media screen and (min-width: @screen-md) {
    display: none;
  }

  & .ant-dropdown-link {
    font-size: 24px;
    color: inherit;

    & svg {
      display: block;
    }
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: @screen-xl) {
    max-width: 1140px;
  }

  @media screen and (min-width: @screen-xxl) {
    max-width: 1140px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1140px;
  }
}

.header-hor-header-fixed-main {
  position: relative;
  background-color: inherit;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .cr-user-info {
    padding: 8px;
    background-color: transparent;
    min-height: auto;

    @media screen and (min-width: @screen-sm) {
      padding-left: 12px;
      padding-right: 12px;
    }

    @media screen and (min-width: @screen-md) {
      padding-left: 20px;

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }

  & .cr-user-info-content {
    display: none;
  }
}

.header-hor-header-fixed-main-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .hor-user-info-name {
    display: none;
  }
}

.search-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
}

.app-layout-hor-header-fixed-main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100% !important;

  & .main-content-view {
    padding-left: 0;
    padding-right: 0;
  }

  & > .container {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & > .home-container {
    padding: 0;

    & .main-content-view {
      padding: 0;
    }
  }
}

.app-sidebar-hor-header-fixed-scrollbar {
  height: calc(100vh - 56px);

  @media screen and (min-width: @screen-xxl) {
    height: calc(100vh - 71px);
  }
}

.autocomplete-search-hor-header-fixed {
  @media screen and (max-width: @screen-sm) {
    margin-right: 0px;
  }
  @media screen and (min-width: @screen-lg) {
    margin-right: calc(30vw - 450px);
  }
}

//Horizontal Main Menu
.app-main-hor-header-fixed-menu {
  background-color: inherit;
  color: inherit;
  border-bottom: 0 none;
  display: none;
  padding-left: 12px;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: 12px;
  }

  @media screen and (min-width: @screen-lg) {
  }

  @media screen and (min-width: @screen-xxl) {
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 10px;
    border-radius: @border-radius-base;
  }

  & .ant-menu-submenu .ant-menu-submenu-title {
    padding: 12px 10px;
  }

  &.ant-menu-horizontal > .ant-menu-item::after,
  &.ant-menu-horizontal > .ant-menu-submenu::after {
    display: none;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) {
    & > .ant-menu-item:hover,
    & > .ant-menu-submenu:hover,
    & > .ant-menu-item-active,
    & > .ant-menu-submenu-active,
    & > .ant-menu-item-open,
    & > .ant-menu-submenu-open,
    & > .ant-menu-item-selected,
    & > .ant-menu-submenu-selected {
      color: inherit;
      border-bottom: 0 none;
    }
  }

  & .ant-menu-submenu-title {
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0 none;
  }
}

// Sidebar Background Image
.hor-header-fixed-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 1;
    opacity: 0.5;
  }

  & > div {
    position: relative;
    z-index: 3;
  }

  & .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
    color: inherit;
  }

  & .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
  & .app-main-sidebar-menu .ant-menu-item a {
    color: inherit;
  }
}

// Drawer
.app-hor-header-fixed-drawer {
  & .ant-drawer-content-wrapper {
    width: 17.5rem !important;
  }

  & .ant-drawer-body {
    padding: 0;
  }
}

.notify-link-active {
  border-radius: 0;
  border-width: 0;
  border-bottom-width: 4px;
  border-color: #c00000 !important;
  border-style: solid;
  .wc-menu-active {
    color: #c00000;
  }
}

.search-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  .pre-text {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0168em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
  }
}

.filter-modal {
  font-family: 'Roboto';
  font-style: normal;
  font-feature-settings: 'tnum' on, 'lnum' on;

  @media screen and (min-width: @screen-md) {
    max-width: 80%;
  }

  @media screen and (min-width: @screen-lg) {
    max-width: 60%;
  }

  .ant-modal-title {
    width: 187px;
    height: 48px;

    font-weight: 500;
    font-size: 32px;
    line-height: 48px;

    color: #333333;
    margin: 0 auto;
  }
  .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #808080;
  }

  .ant-card-head {
    border-bottom: none;
  }
  .ant-card-head-title {
    font-size: 20px;
    line-height: 28px;
  }
  .ant-checkbox-wrapper {
    margin: 12px 20px 12px 0;
    .ant-checkbox + span {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
  }

  .ant-modal-footer {
    margin-top: 32px;
    .ant-col {
      text-align: left;
      &:last-child {
        text-align: center;
        .ant-btn {
          min-width: 100px;
        }
      }
    }
  }
}
