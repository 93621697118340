@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.app-logo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  & img {
    height: 54px;
    margin-right: 10px;
    max-width: unset;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: @screen-sm) {
    display: none;
  }
}

.app-logo-name {
  font-weight: @font-weight-medium;
  font-size: 24px;
  display: none;

  @media screen and (min-width: @screen-sm) {
    display: block;
  }

  &.white {
    color: @text-color-white;
  }
}
