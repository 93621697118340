.table-option {
  display: flex;
  margin: 5px 2px;
  gap: 5px;
}
.table-option {
  white-space: nowrap;
}
.table-input {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 3px;
}
.table-unit {
  width: 15px;
  height: 15px;
  border: 1px solid lightgray;
}
