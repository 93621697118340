@import '../../../shared/styles/variable';

.message-list-item {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0 none !important;
  white-space: inherit;
  line-height: 1.4;

  & .ant-list-item-meta {
    align-items: center;
  }
}

.message-avatar {
  width: 48px;
  height: 48px;
  margin-right: 12px;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: 12px;
  }

  @media screen and (min-width: @screen-md) {
    margin-right: 16px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 16px;
    }
  }
}

.message-list-item-content {
  font-size: @font-size-base;
  color: @text-color-secondary;
  flex: 1;

  & h3 {
    margin-bottom: 2px;
    font-weight: @font-weight-medium;
    font-size: @font-size-base;
  }

  & p {
    margin-bottom: 0;
  }

  & span {
    color: @text-color;
    font-weight: @font-weight-medium;
    margin-right: 8px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}