.slate-container {
  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
  table {
    border-collapse: collapse;
  }
  button {
    background-color: white;
    border: none;
    opacity: 0.5;
  }
  .btnActive {
    opacity: 1;
  }
  .editor-wrapper {
    font-family: 'Helvetica', 'Arial', sans-serif;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
    min-height: 400px;
    height: fit-content;
    height: 600px;
    overflow-y: scroll;
  }
  table {
    width: 100%;
  }
  td {
    height: 50px;
    padding: 0 5px;
  }
  .popup-wrapper {
    display: inline;
    position: relative;
  }
  .popup {
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
  }
  button {
    cursor: pointer;
  }
}
