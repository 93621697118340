@import '../../../shared/styles/variable';

.user-profile-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
}

.title-primary {
  color: #0a66c2;
  font-size: 20px;
}

.user-profile-tabs {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: @screen-sm) {
    flex-direction: row;
  }

  &.ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane,
  &.ant-tabs-left
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 0;
  }

  & .ant-tabs-nav {
    min-width: 200px;
    background-color: @component-background;
    border-radius: @card-radius;
    padding: 20px 0;

    @media screen and (min-width: @screen-xl) {
      min-width: 280px;
    }

    & .ant-tabs-tab {
      color: @text-color;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin-right: 20px !important;
      border-radius: 0 30px 30px 0;

      &:hover,
      &:focus {
        background-color: transparent;
        color: @primary-color;
      }

      [dir='rtl'] & {
        margin-right: 0 !important;
        margin-left: 20px !important;
        border-radius: 30px 0 0 30px;
      }

      & + .ant-tabs-tab {
        margin-top: 1px;
      }
    }

    & .ant-tabs-tab-active {
      color: @primary-color;
      background-color: fade(@primary-color, 10);

      &:hover,
      &:focus {
        color: @primary-color;
        background-color: fade(@primary-color, 10);
      }
    }

    & .ant-tabs-ink-bar {
      display: none;
    }
  }

  & .user-profile-icon {
    display: flex;
    align-items: center;

    & .icon {
      font-size: @font-size-lg;
      margin-right: 16px;

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  & .ant-tabs-content-holder {
    padding-top: 20px;

    [dir='rtl'] & {
      order: 2;
    }

    @media screen and (min-width: @screen-sm) {
      padding-left: 20px;
      padding-top: 0;

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    @media screen and (min-width: @screen-xl) {
      padding-left: 32px;

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 32px;
      }
    }
  }

  & .ant-tabs-content {
    background-color: @component-background;
    border-radius: @card-radius;
    height: 100%;
    padding: 20px;

    & .ant-form-item {
      margin-bottom: 16px;
    }

    & .user-profile-group-btn {
      margin-bottom: 0;
    }
  }
}

.user-profile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 17px;
  background: #fff;
  border: 1px solid #e6e6e6;

  & .user-profile-footer_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
}
.user-profile-group-btn {
  position: relative;

  & .ant-btn {
    & + .ant-btn {
      margin-left: 12px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }
}

.user-profile-form {
  position: relative;
}

.user-profile-form-title {
  font-size: 32px;
  font-weight: @font-weight-bold;
  margin-bottom: 16px;
  text-align: center;
  margin-top: 24px;
}

.user-profile-form-description {
  margin-bottom: 16px;
  text-align: center;
  margin-bottom: 32px;
}

.user-profile-card {
  box-sizing: border-box;
  align-items: center;
  padding-top: 24px;

  border: 1px solid #e6e6e6;
  border-radius: 16px;
}

.notification-list {
  position: relative;
}

.notification-list-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  & .label {
    margin-left: 15px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

.profile-notification {
  position: relative;

  & + .profile-notification {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
    border-top: @border-width-base @border-style-base @border-color-base;
  }
}
