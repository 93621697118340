@import '../../../shared/styles/variable';

.item-tag {
  display: flex;
  background-color: #0a8fdc2c;
  color: @secondary-color;
  margin-right: 10px;
  border-radius: 7px;
  padding: 5px;
}

.text-title {
  font-size: 18pt;
  font-weight: bold;
  display: flex;
}

.text-description {
  display: flex;
  text-align: left;
  white-space: pre;
}

.text-download {
  padding-left: 40px;
  padding-right: 40px;
  background-color: #c000002d;
  color: #c00000;
  border: none;
  width: 200px;
}

.btn-close {
  background-color: white;
  color: #c00000;
  border-color: c00000;
  justify-content: center;
}
