.custom-tags {
  background: #f2f2f2;
  min-height: 48px;
  border-radius: 8px;
  padding: 8px 16px;
  align-items: center;

  .tag-input {
    background: #f2f2f2;
    border: none;
  }

  .empty-input {
    min-width: 220px;
    margin-left: -8px;
  }

  .tag-input-end {
    flex: 1;
  }

  .ant-col:last-child {
    flex: 1;
  }

  .ant-select {
    flex: 1;
    background: #f2f2f2;
    background-color: #f2f2f2;
    border: none;
    width: 100%;
    :focus {
      background: #f2f2f2;
      background-color: #f2f2f2;
      border: none;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f2f2f2;
    border: none;
  }

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

.footer-fixed {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

.ReactTags__tagInput {
  border-radius: 4px;
  display: inline-block;

  input.ReactTags__tagInputField {
    height: 40px;
    margin: 0;
    padding: 0px 8px;
    font-size: 14px;
    border: none;
    background: #f7f7f7;
    min-width: 380px;
    border-radius: 8px;

    &:focus {
      height: 40px;
      margin: 0;
      padding: 0px 8px;
      font-size: 14px;
      outline: none;
    }
  }
}

.ReactTags__editInput {
  border-radius: 4px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__selected {
  display: flex;
  justify-content: center;

  span.ReactTags__tag {
    border: none;
    background: #e2edf8;
    color: #0a66c2;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 8px;
    margin: 0 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .ReactTags__remove {
    cursor: pointer;
    display: flex;
    color: #5b5b5b;
  }
}

.ReactTags__suggestions {
  position: absolute;
  z-index: 100;

  ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: #ffffff;
    width: 200px;
    padding: 5px 0;
    border-radius: 8px;
  }

  li {
    padding: 5px 10px;
    margin: 0;
    text-align: left;

    mark {
      text-decoration: underline;
      background: none;
    }

    &.ReactTags__activeSuggestion {
      background: #e2edf8;
      color: #000000;
      cursor: pointer;

      span mark {
        color: #0a66c2;
      }
    }
  }
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #363434;
  padding: 5px;
}

.ReactTags__error {
  color: #e03131;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    padding: 0 5px;
  }
}
